import { ReactNode, createContext, useContext, useState } from 'react';
import moment from 'moment';
import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Campaign } from '../../types';

type SearchCampaignsAndPurposesContextType = {
    campaignsFiltersString: string;
    setCampaignsFiltersString: (value: string) => void;
    purposesFiltersString: string;
    setPurposesFiltersString: (value: string) => void;
    oneMonthAgo: string;
    campaignsValues: SearchFormValue;
    setCampaignsValues: (values: SearchFormValue) => void;
    purposesValues: SearchFormValue;
    setPurposesValues: (values: SearchFormValue) => void;
    campaignDetails: Campaign | null;
    setCampaignDetails: (campaign: Campaign | null) => void;
    campaignCurrentPage: number | null;
    setCampaignCurrentPage: (value: number | null) => void;
};

const SearchCampaignsAndPurposesContext = createContext<
    SearchCampaignsAndPurposesContextType | undefined
>(undefined);

export const useSearchCampaignsAndPurposesContext = () => {
    const context = useContext(SearchCampaignsAndPurposesContext);

    if (context === undefined) {
        throw new Error(
            'useSearchCampaignsAndPurposesContext must be used with a SearchCampaignsAndPurposesContext'
        );
    }

    return context;
};

export const SearchCampaignsAndPurposesContextProvider = ({
    children
}: {
    children: ReactNode;
}) => {
    const oneMonthAgo = moment(new Date(moment().add(-1, 'months').format())).format('YYYY-MM-DD');

    const [campaignsFiltersString, setCampaignsFiltersString] = useState<string>(
        '&created_at_after=' + oneMonthAgo
    );
    const [campaignsValues, setCampaignsValues] = useState<SearchFormValue>({
        formValue: {
            created_at_after: oneMonthAgo
        },
        filterValues: {
            created_at_after: oneMonthAgo
        }
    });

    const [purposesFiltersString, setPurposesFiltersString] = useState<string>(
        '&campaign__created_at_after=' + oneMonthAgo
    );
    const [purposesValues, setPurposesValues] = useState<SearchFormValue>({
        formValue: {
            campaign__created_at_after: oneMonthAgo
        },
        filterValues: {
            campaign__created_at_after: oneMonthAgo
        }
    });

    const [campaignDetails, setCampaignDetails] = useState<Campaign | null>(null);
    const [campaignCurrentPage, setCampaignCurrentPage] = useState<number | null>(null);

    const value = {
        campaignsFiltersString,
        setCampaignsFiltersString,
        purposesFiltersString,
        setPurposesFiltersString,
        oneMonthAgo,
        campaignsValues,
        setCampaignsValues,
        purposesValues,
        setPurposesValues,
        campaignDetails,
        setCampaignDetails,
        campaignCurrentPage,
        setCampaignCurrentPage
    };

    return (
        <SearchCampaignsAndPurposesContext.Provider value={value}>
            {children}
        </SearchCampaignsAndPurposesContext.Provider>
    );
};
