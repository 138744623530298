import {
    ExportButton as ExportButtonType,
    ListField
} from '@applications-terrains/birdz-react-library';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import useFetchData from '../../../hooks/useFetchData';
import EmptyPageWithLoader from '../Pages/EmptyPageWIthLoader';
import PaginationComponent from '../PaginationComponent';
import { useEffect, useState } from 'react';
import PageSizeSelector, { PaginationType } from '../PageSizeSelector';
import EmptyPage from '../Pages/EmptyPage';
import { useFetchContext } from '../../../contexts/FetchContext';
import './b-table.scss';
import ExportButton from './export/ExportButton';
import { omit } from 'lodash';
// import { useLocation, useNavigate } from 'react-router-dom';

type TableProps = {
    fields: ListField[];
    actions?: any[];
    endpoint: string;
    model: string;
    exportButtons?: (ExportButtonType & { id: string })[];
    refresh?: boolean;
    [key: string]: any;
};

const BTable = ({
    fields,
    actions,
    endpoint,
    model,
    exportButtons,
    refresh = false
}: TableProps) => {
    // const navigate = useNavigate();
    // const { pathname, search } = useLocation();
    const [internalLoading, setInternalLoading] = useState(true);

    const [pagination, setPagination] = useState<PaginationType>({
        pageSize: 25, // Default value
        currentPage: 1,
        totalItems: null
    });

    useEffect(() => {
        const savedPageSize = localStorage.getItem('pageSize');
        savedPageSize &&
            pagination.pageSize !== parseInt(savedPageSize) &&
            setPagination((prev) => ({ ...prev, pageSize: parseInt(savedPageSize) }));
    }, [pagination]);

    const { dataRefs, setDataRefs } = useFetchContext();

    // useEffect(() => {
    //     const getPageFromUrl = (url: string) => {
    //         const pageMatch = url.match(/&page=(\d+)/);
    //         const pageNumber = pageMatch ? parseInt(pageMatch[1], 10) : null;
    //         return pageNumber;
    //     };
    //     const currentPage = getPageFromUrl(search);
    //     if (currentPage && currentPage !== pagination.currentPage) setPagination({
    //         ...pagination,
    //         currentPage
    //     })
    // }, [search, pagination])

    const fullEndpoint = `${endpoint}${endpoint.includes('?') ? '&' : '?'}size=${pagination.pageSize}&page=${pagination.currentPage}&ordering=-created_at`;

    const enableFetch =
        !(model in dataRefs) || (model in dataRefs && dataRefs[model].endpoint !== fullEndpoint);

    const { isLoading, error, refetch, isFetched } = useFetchData({
        key: model,
        endpoint: fullEndpoint,
        enableFetch
    });

    //ensure a loader is always shown on page change, and cancels it 300ms after data has arrived
    useEffect(() => {
        (pagination.totalItems !== null || isFetched) &&
            setTimeout(() => {
                setInternalLoading(false);
            }, 300);
    }, [pagination, isFetched]);

    useEffect(() => {
        if (refresh) {
            refetch();
            setInternalLoading(true);
            setPagination((pagination) => ({ ...pagination, totalItems: null }));
            model in dataRefs && setDataRefs(omit(dataRefs, [model]));
        }
    }, [refresh, refetch, model, dataRefs, setDataRefs]);

    useEffect(() => {
        const pageSizeLocalStorage = localStorage.getItem('pageSize');
        const pageSize = pageSizeLocalStorage
            ? parseInt(pageSizeLocalStorage)
            : pagination.pageSize;
        if (
            model in dataRefs &&
            dataRefs[model]?.count &&
            dataRefs[model]?.count !== pagination.totalItems
        ) {
            setPagination({ ...pagination, pageSize, totalItems: dataRefs[model]?.count });
        }
    }, [dataRefs, pagination, model]);

    const result = `résultat${pagination.totalItems && pagination.totalItems <= 1 ? '' : 's'} ${pagination.currentPage * pagination.pageSize - (pagination.pageSize - 1)} ${
        pagination.totalItems && pagination.totalItems > 1
            ? `- 
    ${pagination.currentPage * pagination.pageSize < pagination.totalItems ? pagination.currentPage * pagination.pageSize : pagination.totalItems}`
            : ''
    } / ${pagination.totalItems}
`;

    return (
        <Paper
            sx={{
                position: 'relative',
                border: '1px solid rgba(224, 224, 224, 1)',
                boxSizing: 'border-box',
                p: 2,
                height: '100%'
            }}
            className="d-flex flex-column table-container"
            elevation={0}>
            {isLoading || internalLoading ? (
                <EmptyPageWithLoader />
            ) : dataRefs[model]?.count === 0 ? (
                <EmptyPage message="Aucun résultat" className="transitioned" />
            ) : error ? (
                <EmptyPage message="Oups... Une erreur est survenue" className="transitioned" />
            ) : (
                <>
                    <Box
                        className="w-100 d-flex align-items-center justify-content-between transitioned"
                        sx={{
                            pl: 4,
                            pr: 2,
                            color: '#666666',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            boxSizing: 'border-box'
                        }}>
                        {result}
                        <Box minHeight={50} className="d-flex align-items-center">
                            {exportButtons &&
                                exportButtons.length > 0 &&
                                exportButtons.map((exportButton, index) => (
                                    <ExportButton
                                        key={exportButton.name + index}
                                        exportButton={exportButton}
                                        endpoint={exportButton.requestEndpoint || ''}
                                        pagination={pagination}
                                    />
                                ))}
                        </Box>
                    </Box>

                    <TableContainer
                        className="transitioned"
                        sx={{
                            mt: 4,
                            borderTop: '1px solid rgba(224, 224, 224, 1)',
                            boxSizing: 'border-box'
                        }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {fields.map((el) => (
                                        <TableCell
                                            sx={{ py: 2 }}
                                            className="header-cell"
                                            key={el.label + 'head'}>
                                            {el.label}
                                        </TableCell>
                                    ))}
                                    {actions && actions?.length > 0 && (
                                        <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody className="" sx={{ overflow: 'auto' }}>
                                {dataRefs[model]?.results?.map((row: any, index: number) => {
                                    return (
                                        <TableRow
                                            data-label={row.name}
                                            key={'tableRow' + row.name + index}
                                            className={`'table-row'}`}>
                                            {fields.map((el) => {
                                                const transform = el?.transform
                                                    ? el?.transform(row[el.name], row)
                                                    : '';
                                                return (
                                                    <TableCell
                                                        key={'tableRow' + el.label + 'Cell' + index}
                                                        className="cell">
                                                        <Box className="inner-cell">
                                                            {transform || row[el.name] || ''}
                                                        </Box>
                                                    </TableCell>
                                                );
                                            })}
                                            {actions && actions?.length > 0 && (
                                                <TableCell width={'1px'}>
                                                    <Box
                                                        className="d-flex"
                                                        sx={{
                                                            textAlign: 'right',
                                                            marginRight: '-16px'
                                                        }}>
                                                        {actions?.map(
                                                            (action) =>
                                                                typeof action.render ===
                                                                    'function' && (
                                                                    <Fragment key={action.name}>
                                                                        {action?.render(row)}
                                                                    </Fragment>
                                                                )
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {dataRefs[model]?.count > 5 && (
                        <Box className="w-100 d-flex flex-grow-1 flex-column">
                            <Box
                                className="BTable-footer d-flex justify-content-between align-items-center"
                                sx={{
                                    pt: 2,
                                    mt: 'auto'
                                }}>
                                <PaginationComponent
                                    pagination={pagination}
                                    setPagination={(val) => {
                                        setPagination(val);
                                        // const baseUrl = pathname + search;
                                        // let url = baseUrl.includes('&page=')
                                        //     ? baseUrl.replace(/(&page=)\d+/, `$1${val.currentPage}`)
                                        //     : baseUrl + '&page=' + val.currentPage;
                                        // navigate(url);
                                    }}
                                />
                                <PageSizeSelector
                                    pagination={pagination}
                                    setPagination={setPagination}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Paper>
    );
};

export default BTable;
