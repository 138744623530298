import React, { useState } from 'react';
import PurposeDetail from './PurposeDetail';
import {
  BirdzModal,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';
import { Box, Tab, Tabs } from '@mui/material';
import EmptyPageWithLoader from '../../../Tools/Pages/EmptyPageWIthLoader';

type PurposeDetailModalProps = {
  sentItem: any;
  onClose(): void;
};

const PurposeDetailModal = ({ sentItem, onClose }: PurposeDetailModalProps) => {
  const main_purpose = sentItem;
  const linked_purpose = sentItem.linked_purpose;
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_: any, newValueTab: number) => {
    setValueTab(newValueTab);
  };

  return (
    <BirdzModal open={true} maxWidth={false}>
      {sentItem === 'loading' ? <EmptyPageWithLoader /> : <>
      <BirdzModalTitle
        onClose={() => {
          onClose();
        }}>
        Détail envoi
      </BirdzModalTitle>
      <BirdzModalContent>
        <Box sx={{ height: '80vh' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={valueTab} onChange={handleChangeTab}>
              <Tab label="Intention principale" />
              {linked_purpose !== null && linked_purpose !== undefined && (
                <Tab label="Intention liée" />
              )}
            </Tabs>
          </Box>
          <Box sx={{ p: 3 }}>
            {valueTab === 0 && <PurposeDetail value={main_purpose} />}
            {valueTab === 1 && linked_purpose !== null && linked_purpose !== undefined && (
              <PurposeDetail value={linked_purpose} />
            )}
          </Box>
        </Box>
      </BirdzModalContent>
      </>}
    </BirdzModal>
  );
};

export default PurposeDetailModal;
