import { Outlet } from 'react-router-dom';
import { SearchCampaignsAndPurposesContextProvider } from './Lists/SearchCampaignsAndPurposesContext';
import { Box } from '@mui/material';
import useAutoRedirect from '../../hooks/useAutoRedirect';

const Sendings = () => {
    useAutoRedirect({
        baseUrl: '/sendings',
        targetUrl: '/sendings/sent-list?tab=campaigns'
    });
    return (
        <Box className="h-100 d-flex flex-column">
            <SearchCampaignsAndPurposesContextProvider>
                <Outlet />
            </SearchCampaignsAndPurposesContextProvider>
        </Box>
    );
};

export default Sendings;
