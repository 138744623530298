import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useAutoRedirect = ({ baseUrl, targetUrl }: { baseUrl: string; targetUrl: string }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        pathname === baseUrl && navigate(targetUrl);
    }, [pathname, navigate, baseUrl, targetUrl]);
};

export default useAutoRedirect;