import React from 'react';
import { formatData } from './ParametersUtils';
import { TextField } from '@mui/material';

type ParametersTextProps = {
    field: any;
    value: any;
    onFieldChange(fieldId: string, value: any): void;
    setValidity(validity: boolean): void;
    disabled: boolean;
};
export const ParametersText = ({
    field,
    value,
    onFieldChange,
    setValidity,
    disabled
}: ParametersTextProps) => {
    let minLength = Number.isInteger(field.min_length) ? field.min_length : null;
    let maxLength = Number.isInteger(field.max_length) ? field.max_length : null;
    if (field.length) {
        minLength = field.length;
        maxLength = field.length;
    }

    let valueLength =
        typeof value === 'number' || typeof value === 'string' ? value.toString().length : null;

    let step = field.step ?? null;
    let minValue = field.min ?? null;
    let maxValue = field.max ?? null;

    // Errors management
    let errorRaised = false;
    if (minLength !== null && valueLength !== null && valueLength < minLength) {
        errorRaised = true;
    }
    if (maxLength !== null && valueLength !== null && valueLength > maxLength) {
        errorRaised = true;
    }

    if (value && step && !Number.isInteger(value / step)) {
        errorRaised = true;
    }
    if ((minValue !== null && value < minValue) || value === '') {
        errorRaised = true;
    }
    if ((maxValue !== null && value > maxValue) || value === '') {
        errorRaised = true;
    }

    if (field?.label) {
        if (errorRaised) {
            setValidity(false);
        } else {
            setValidity(true);
        }
    }

    // rules helper
    let rulesHelper = [];
    if (minLength !== null) {
        rulesHelper.push('min : ' + minLength + ' caractères');
    }
    if (maxLength !== null) {
        rulesHelper.push('max : ' + maxLength + ' caractères');
    }
    if (step) {
        rulesHelper.push('intervalle : ' + step);
    }
    if (minValue !== null) {
        rulesHelper.push('valeur min : ' + minValue);
    }
    if (maxValue !== null) {
        rulesHelper.push('valeur max : ' + maxValue);
    }

    return (
        <>
            {rulesHelper.length > 0 && <>({rulesHelper.join(', ')})</>}
            <TextField
                id={'parameter-text-input'}
                type={field.type}
                size="small"
                fullWidth
                onChange={(e: any) => {
                    if (
                        (typeof field.validate === 'function' && field.validate(e.target.value)) ||
                        typeof field.validate === 'undefined'
                    ) {
                        const value = formatData(field.type, e.target.value);
                        onFieldChange(field.id, value);
                    }
                }}
                InputProps={{
                    inputProps: {
                        minLength: minLength,
                        maxLength: maxLength,
                        step: step
                    }
                }}
                // value={value}
                defaultValue={value}
                disabled={disabled}
            />
        </>
    );
};
