import React, { useCallback, useEffect, useState } from 'react';
import _, { get, set, uniq } from 'lodash';
import './Parameters.scss';
import ParametersField from './ParametersField';
import { setNullValues } from './ParametersUtils';
import { Device } from '../types';
import { Button } from '@mui/material';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import { useForm } from 'react-hook-form';
import useEncoderService from '../Common/Services/useEncoderService';
import { useCampaignContext } from '../../contexts/CampaignContext';

type ParametersProps = {
    codec: string;
    values?: PayloadClear;
    duplicationValues?: PayloadClear | null;
    onChange(parameters: PayloadClearByDeviceTypes): void;
    onErrors?(errors: any): void;
    setHasError?: (value: boolean) => void;
    id?: string;
    setEmptyValuesToNull?: boolean; // dans certains cas, on ne veut pas des valeurs nulles par défaut (header HR)
};

type PayloadClear = {
    [key: string]: any;
};

export type PayloadClearByDeviceTypes = {
    [deviceType: string]: PayloadClear;
};

export const getPayloadClearByDeviceType = (
    devices: Device[],
    payloadClear: PayloadClear
): PayloadClearByDeviceTypes => {
    const payloadClearByDeviceTypes: PayloadClearByDeviceTypes = {};
    _.uniq(
        devices?.map((device) => {
            return device.device_type;
        })
    ).forEach((deviceType: any) => {
        payloadClearByDeviceTypes[deviceType] = payloadClear;
    });
    return payloadClearByDeviceTypes;
};

export const getPayloadClear = (
    payloadClearByDeviceTypes: PayloadClearByDeviceTypes
): PayloadClear => {
    if (!payloadClearByDeviceTypes) return {};
    const res = Object.values(payloadClearByDeviceTypes)[0] || {};
    return res;
};

const Parameters = ({
    values: creationValues = {},
    duplicationValues = null,
    onChange,
    onErrors,
    setHasError,
    codec,
    id,
    setEmptyValuesToNull = true
}: ParametersProps) => {    
    const [encoderFormatedData, setEncoderFormatedData] = useState<any>({});
    const [optionalCheckedFields, setOptionalCheckedFields] = useState<string[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [optionalFields, setOptionalFields] = useState<string[]>([]);
    const [displayPayload, setDisplayPayload] = useState<boolean>(false);
    const { encoderService } = useEncoderService();

    const values = duplicationValues !== null ? duplicationValues : creationValues;

    const { setValue, getValues, reset } = useForm<any>({
        defaultValues: values
    });

    const { duplicate } = useCampaignContext();

    useEffect(() => {
        typeof setHasError === 'function' && setHasError(errors.length > 0);
    }, [errors, setHasError]);

    useEffect(() => {
        // Set all values to null
        const getInitialValues = () => {
            let initialValues: any = {};
            encoderService.getAllFields().forEach((field: string) => {
                initialValues[field] = null;
                setValue(field, null);
            });
            return encoderService.formatToApi(initialValues);
        };

        encoderService.loadEncoder(codec);
        setEncoderFormatedData(encoderService.formatData());
        if (_.isEmpty(values) && setEmptyValuesToNull) {
            onChange(getInitialValues());
        }

        const getFieldsToCheck = (values: any) => {
            let checkedCheckboxes: any[] = [];
            for (let value in values) {
                // if value defined
                if (values[value] !== null) {
                    // if field is not mandatory
                    if (encoderService.fieldIsOptional(value)) {
                        checkedCheckboxes.push(value);
                    }

                    // check all parents
                    const getParents = (value: string): string[] => {
                        let parents = [];
                        let valueSplit = value.split('.');
                        valueSplit.pop();
                        let parentId = valueSplit.join('.');
                        parents.push(parentId);
                        if (valueSplit.length > 2) {
                            parents = parents.concat(getParents(valueSplit.join('.')));
                        }
                        return parents;
                    };
                    uniq(getParents(value)).forEach((child: string) => {
                        checkedCheckboxes.push(child);
                    });
                }
            }
            return uniq(checkedCheckboxes);
        };

        let fieldsToCheck = getFieldsToCheck(
            encoderService.convertNestedObjectToSplitNotation(values)
        );
        setOptionalCheckedFields(fieldsToCheck);
        // eslint-disable-next-line
    }, [codec, duplicate]);

    const validate = useCallback(
        () => {
            const formValue = getValues();
            let newErrors: string[] = [];

            // check optional fields which are checked
            const newOptionalFields = encoderService.getOptionalFields(optionalCheckedFields);

            if (!_.isEqual(optionalFields, newOptionalFields)) {
                setOptionalFields(newOptionalFields);
            }
            const mandatoryFields = encoderService.getMandatoryFields(optionalCheckedFields);
            const nestedValues = encoderService.convertNestedObjectToSplitNotation(formValue);

            mandatoryFields.forEach((mandatoryField: string) => {
                if (
                    nestedValues[mandatoryField] === '' ||
                    nestedValues[mandatoryField] === null ||
                    nestedValues[mandatoryField] === undefined
                ) {
                    newErrors.push(mandatoryField);
                }
            });

            // check conditional fields which are visible
            mandatoryFields.forEach((mandatoryField: any) => {
                // on récupère toute l'arbo du champ (parents, grands parents) pour aller checker si il y a des champs conditionnels à un plus haut niveau
                let allNodes = encoderService.getAllParentIds(mandatoryField);
                allNodes.push(mandatoryField);

                let currentProperty: any;
                let fieldConditionalIsSatisfyed = false;
                // on part du niveau le plus haut et on redescends petit à petit pour checker si le champ conditionel est satisfait et valide
                allNodes.forEach((currentNode: string) => {
                    currentProperty = encoderService.getFieldById(currentNode);
                    if (encoderService.fieldIsConditional(currentNode) && currentProperty?.if) {
                        fieldConditionalIsSatisfyed = encoderService.conditionIsSatisfyed(
                            currentProperty?.if,
                            formValue
                        );
                        if (!fieldConditionalIsSatisfyed) {
                            newErrors = newErrors.filter(
                                (errorField: string) => errorField !== mandatoryField
                            );
                        }
                    }
                });
            });

            if (!_.isEqual(newErrors, errors)) {
                setErrors(newErrors);
            }

            if (newErrors.length === 0) {
                setTimeout(() => {
                    onChange(formValue);
                });
            } else if (typeof onErrors === 'function') {
                onErrors(newErrors);
            }
        },
        //eslint-disable-next-line
        [encoderService, optionalCheckedFields, errors]
    );

    useEffect(() => {
        validate();
    }, [validate]);

    const checkField = (propertyId: string) => {
        const newOptionalCheckedFields = Array.from(
            new Set([...optionalCheckedFields, propertyId])
        );
        setOptionalCheckedFields(newOptionalCheckedFields);
    };

    const uncheckField = (propertyId: string) => {
        let newValues = Object.assign({}, getValues());
        let fields = get(newValues, propertyId);
        fields = setNullValues(fields);
        newValues = set(newValues, propertyId, fields);
        reset(newValues);
        onChange(newValues);
        setOptionalCheckedFields(
            optionalCheckedFields.filter(
                (optionalCheckedField: string) => optionalCheckedField !== propertyId
            )
        );
    };

    const onFieldChange = (fieldName: string, fieldValue: any) => {
        setValue(fieldName, fieldValue);
        validate();
    };

    const MandatoryCheckbox = ({
        propertyId,
        onCheck,
        onUncheck,
        disabled = false
    }: {
        propertyId: string;
        onCheck: (propertyId: string) => void;
        onUncheck: (propertyId: string) => void;
        disabled: boolean;
    }) => {
        return (
            <input
                id={'parameter-MandatoryCheckbox-checkbox'}
                type="checkbox"
                onChange={(e: any) => {
                    if (e.target.checked) {
                        onCheck(propertyId);
                    } else {
                        onUncheck(propertyId);
                    }
                }}
                checked={optionalCheckedFields.includes(propertyId)}
                disabled={disabled}
            />
        );
    };

    const isMandatoryCheckboxCanBeCheck = useCallback(
        (currentPropertyId: string) => {
            return encoderService.mandatoryCheckboxCanBeCheck(
                currentPropertyId,
                optionalCheckedFields
            );
        },
        [optionalCheckedFields, encoderService]
    );

    const displayForm = (properties: any) => {
        if (properties) {
            return (
                <div>
                    {Object.keys(properties).map((property: any) => {
                        const formValue = getValues();
                        const currentProperty = properties[property];
                        const currentPropertyId = currentProperty.id;

                        const level = currentPropertyId.split('.').length;
                        const label = `${currentProperty.label} (${currentPropertyId})`;
                        const hasProperties = currentProperty?.properties;
                        const mandatoryCheckboxCanBeCheck =
                            isMandatoryCheckboxCanBeCheck(currentPropertyId);
                        const mandatoryCheckbox = (
                            <MandatoryCheckbox
                                propertyId={currentPropertyId}
                                onCheck={checkField}
                                onUncheck={uncheckField}
                                disabled={!mandatoryCheckboxCanBeCheck}
                            />
                        );
                        const fieldIsOptional = optionalFields.includes(currentPropertyId);
                        const fieldIsValid = !errors.includes(currentPropertyId);
                        const fieldValue = formValue ? get(formValue, currentPropertyId) : null;
                        const fieldIsConditional =
                            encoderService.fieldIsConditional(currentPropertyId);
                        let fieldConditionalIsSatisfyed = false;
                        if (fieldIsConditional && currentProperty?.if) {
                            fieldConditionalIsSatisfyed = encoderService.conditionIsSatisfyed(
                                currentProperty?.if,
                                formValue
                            );
                            if (!fieldConditionalIsSatisfyed) {
                                return <React.Fragment key={currentPropertyId}></React.Fragment>;
                            }
                        }

                        if (!currentProperty.label) {
                            return <React.Fragment key={currentPropertyId}></React.Fragment>;
                        }

                        return (
                            <div
                                key={currentPropertyId}
                                className={`level-${level}  ${hasProperties || level === 1 ? 'block' : ''}`}>
                                {hasProperties ? (
                                    <>
                                        <h2>
                                            {currentProperty?.mandatory === false &&
                                                mandatoryCheckbox}{' '}
                                            {label}
                                        </h2>
                                        <div className="">
                                            {displayForm(currentProperty.properties)}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="field">
                                            {
                                                <ParametersField
                                                    mandatoryCheckbox={mandatoryCheckbox}
                                                    disabled={fieldIsOptional}
                                                    field={currentProperty}
                                                    onFieldChange={onFieldChange}
                                                    defaultValue={fieldValue}
                                                    valid={fieldIsValid}
                                                    setValidity={(validity: boolean) => {
                                                        // console.log('setValidity for ' + currentProperty.id, validity)
                                                    }}
                                                />
                                            }
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return <></>;
    };

    return (
        <form>
            <div className={`parameters ${errors.length && 'has-error'}`}>
                <AppPaper sx={{ pt: 1, mb: 1 }}>
                    {displayForm(encoderFormatedData?.properties)}
                </AppPaper>

                <Button
                    id={'parameter-display_payload-button'}
                    variant="outlined"
                    size="small"
                    onClick={() => setDisplayPayload(!displayPayload)}>
                    {displayPayload ? 'Masquer' : 'Afficher'} le payload
                </Button>
                {displayPayload && (
                    <>
                        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
                        <pre>Champs en erreur: {JSON.stringify(errors, null, 2)}</pre>
                    </>
                )}
            </div>
        </form>
    );
};

export default Parameters;
